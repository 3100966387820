import * as React from 'react'

import styles from './styles.module.css'

interface IRepoRowProps {
  repo: string
  handleRemove: (repoName: string) => Promise<any>
}

class RepoRow extends React.PureComponent<IRepoRowProps> {
  public render(): JSX.Element {
    const { repo, handleRemove } = this.props

    return (
      <li className={styles.repoRow}>
        <a
          rel="noopener noreferrer"
          href={`https://github.com/getndazn/${repo}`}
          target="_blank"
          className={styles.repoLink}
        >
          {repo}
        </a>
        <button
          className={styles.repoCancelButton}
          onClick={() => handleRemove(repo)}
        >
          X
        </button>
      </li>
    )
  }
}

export default RepoRow
