// eslint-disable
import * as React from 'react'

import AppContext from '../../../../context/appContext'

import Card from '../../components/Card'

import styles from './styles.module.css'

class Columns extends React.PureComponent<{}> {
  static context: React.ContextType<typeof AppContext>

  public render(): JSX.Element {
    const { prsNoApprovals, prsOneApproval, prsReady } = this.context

    /* eslint-disable */
    return (
      <div className={styles.columnsContainer}>
        <div className={styles.column}>
          <div className={styles.columnTitle}>
            No approvals
            <span
              className={styles.titleCount}
            >{`(${prsNoApprovals.length})`}</span>
          </div>
          <div className={styles.columnRows}>
            {prsNoApprovals.map((pr: any) => (
              <Card key={pr.id} pullRequest={pr} />
            ))}
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.columnTitle}>
            One approval
            <span
              className={styles.titleCount}
            >{`(${prsOneApproval.length})`}</span>
          </div>
          <div className={styles.columnRows}>
            {prsOneApproval.map((pr: any) => (
              <Card key={pr.id} pullRequest={pr} />
            ))}
          </div>
        </div>
        <div className={styles.column}>
          <div className={styles.columnTitle}>
            Ready for merge
            <span className={styles.titleCount}>{`(${prsReady.length})`}</span>
          </div>
          <div className={styles.columnRows}>
            {prsReady.map((pr: any) => (
              <Card key={pr.id} pullRequest={pr} />
            ))}
          </div>
        </div>
      </div>
    )
    /* eslint-enable */
  }
}

Columns.contextType = AppContext

export default Columns
