import * as React from 'react'

import styles from './styles.module.css'
import { PullRequest, PullRequestReview } from '../../../../utils/github'

import { format, formatDistanceToNow } from 'date-fns'
import emoji from 'node-emoji'

interface ICardProps {
  pullRequest: PullRequest
}

class Card extends React.PureComponent<ICardProps> {
  public render(): JSX.Element {
    const { pullRequest }: { pullRequest: PullRequest } = this.props
    const {
      additions,
      changedFiles,
      createdAt,
      updatedAt,
      deletions,
      repoName,
      title,
      url
    } = pullRequest

    const prDate = new Date(createdAt)
    const prUpdateDate = new Date(updatedAt)
    let daysPassedBy = formatDistanceToNow(prDate, { addSuffix: true })
    if (prUpdateDate.getTime() - prDate.getTime() > 15 * 60 * 1000) {
      daysPassedBy =
        'updated ' + formatDistanceToNow(prUpdateDate, { addSuffix: true })
    }
    const prCreationDate = format(prDate, 'MMM do yyyy HH:mm')

    return (
      <a
        rel="noopener noreferrer"
        className={styles.card}
        href={url}
        target="_blank"
      >
        <div className={styles.cardRepo}>{repoName}</div>
        <div className={styles.cardTitle}>{emoji.emojify(title)}</div>
        {this.renderUser()}
        {this.renderApprovals()}
        {this.renderComments()}
        {this.renderChanges()}
        <div className={styles.cardFooter}>
          <div className={styles.fileDiff}>
            <span>{changedFiles} Files</span>
            <span>
              {additions > 0 ? `+${additions}` : ''}
              {additions > 0 && deletions > 0 ? ' / ' : ''}
              {deletions > 0 ? `-${deletions}` : ''} Lines
            </span>
          </div>
          <div className={styles.cardDate}>
            <div className={styles.cardDateAgo}>{daysPassedBy}</div>
            <div className={styles.cardDateCreated}>{prCreationDate}</div>
          </div>
        </div>
      </a>
    )
  }

  private renderUser(): JSX.Element {
    const { pullRequest }: { pullRequest: PullRequest } = this.props
    const { author } = pullRequest

    return (
      <div className={styles.owner}>
        <img
          alt={author.login}
          src={author.avatarUrl}
          className={styles.avatar}
          style={{ marginLeft: 0 }}
        />
        <p className={styles.userName}>{author.login}</p>
      </div>
    )
  }

  private renderApprovals(): JSX.Element | null {
    return this.renderReviewers(
      this.props.pullRequest.reviews.approved,
      <svg
        className={styles.approvalIcon}
        viewBox="0 0 16 16"
        version="1.1"
        width="20"
        height="20"
      >
        <path
          fillRule="evenodd"
          d="M13.78 4.22a.75.75 0 010 1.06l-7.25 7.25a.75.75 0 01-1.06 0L2.22 9.28a.75.75 0 011.06-1.06L6 10.94l6.72-6.72a.75.75 0 011.06 0z"
        ></path>
      </svg>
    )
  }

  private renderComments(): JSX.Element | null {
    return this.renderReviewers(
      this.props.pullRequest.reviews.commented,
      <svg
        className={styles.commentIcon}
        viewBox="0 0 16 16"
        version="1.1"
        width="20"
        height="20"
      >
        <path
          fillRule="evenodd"
          d="M2.75 2.5a.25.25 0 00-.25.25v7.5c0 .138.112.25.25.25h2a.75.75 0 01.75.75v2.19l2.72-2.72a.75.75 0 01.53-.22h4.5a.25.25 0 00.25-.25v-7.5a.25.25 0 00-.25-.25H2.75zM1 2.75C1 1.784 1.784 1 2.75 1h10.5c.966 0 1.75.784 1.75 1.75v7.5A1.75 1.75 0 0113.25 12H9.06l-2.573 2.573A1.457 1.457 0 014 13.543V12H2.75A1.75 1.75 0 011 10.25v-7.5z"
        ></path>
      </svg>
    )
  }

  private renderChanges(): JSX.Element | null {
    return this.renderReviewers(
      this.props.pullRequest.reviews.changeRequested,
      <svg
        className={styles.changesRequestedIcon}
        viewBox="0 0 16 16"
        version="1.1"
        width="16"
        height="16"
        aria-hidden="true"
      >
        <path
          fillRule="evenodd"
          d="M2.75 1.5a.25.25 0 00-.25.25v12.5c0 .138.112.25.25.25h10.5a.25.25 0 00.25-.25V4.664a.25.25 0 00-.073-.177l-2.914-2.914a.25.25 0 00-.177-.073H2.75zM1 1.75C1 .784 1.784 0 2.75 0h7.586c.464 0 .909.184 1.237.513l2.914 2.914c.329.328.513.773.513 1.237v9.586A1.75 1.75 0 0113.25 16H2.75A1.75 1.75 0 011 14.25V1.75zm7 1.5a.75.75 0 01.75.75v1.5h1.5a.75.75 0 010 1.5h-1.5v1.5a.75.75 0 01-1.5 0V7h-1.5a.75.75 0 010-1.5h1.5V4A.75.75 0 018 3.25zm-3 8a.75.75 0 01.75-.75h4.5a.75.75 0 010 1.5h-4.5a.75.75 0 01-.75-.75z"
        ></path>
      </svg>
    )
  }

  private renderReviewers(
    reviews: PullRequestReview[],
    label: JSX.Element | 'Comments:' | 'Requested Changes:'
  ): JSX.Element | null {
    const filteredUserId: any = {}

    const filteredReviews = reviews.filter((review: PullRequestReview) => {
      if (!filteredUserId[review.author.login]) {
        filteredUserId[review.author.login] = true
        return true
      }
      return false
    })

    if (filteredReviews.length === 0) {
      return null
    }

    return (
      <div className={styles.cardApprovals}>
        <p className={styles.cardApprovalsTitle}>{label}</p>
        {filteredReviews.map(({ author }) => (
          <div key={author.login} className={styles.cardReviewer}>
            <img
              alt={author.login}
              src={author.avatarUrl}
              className={styles.avatar}
            />
            <p className={styles.userName}>{author.login}</p>
          </div>
        ))}
      </div>
    )
  }
}

export default Card
