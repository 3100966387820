import * as React from 'react'

import DashboardPage from './modules/dashboard/pages/DashboardPage'
import LoginPage from './modules/login/pages/LoginPage'

interface IAppState {
  loading: boolean
  page: 'app' | 'login'
  hasToken: boolean
}

interface IAppProps {}

class App extends React.PureComponent<IAppProps, IAppState> {
  constructor(props: IAppProps) {
    super(props)

    const token = localStorage.getItem('dazn_pr_token')

    this.state = {
      page: 'app',
      loading: true,
      hasToken: token !== ''
    }

    this.onChangePage = this.onChangePage.bind(this)
  }

  public render(): JSX.Element {
    const { hasToken, page } = this.state

    if (hasToken && page === 'app') {
      // @ts-expect-error
      return <DashboardPage onChangePage={this.onChangePage} />
    }

    // @ts-expect-error
    return <LoginPage onChangePage={this.onChangePage} />
  }

  private onChangePage(page: 'app' | 'login'): void {
    const token = localStorage.getItem('dazn_pr_token')

    this.setState({
      page,
      hasToken: token !== ''
    })
  }
}

export default App
