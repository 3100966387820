import React, { ComponentType, FC } from 'react'
import {
  withAuthenticationRequired,
  WithAuthenticationRequiredOptions
} from '@auth0/auth0-react'

const LoadingComponent = () => <>Authenticating...</>

export const protectComponent = (
  component: ComponentType,
  options?: WithAuthenticationRequiredOptions
): FC =>
  withAuthenticationRequired(component, {
    onRedirecting: LoadingComponent,
    ...options
  })
