import Github, { RepoCheckResult } from './github'

class Session {
  private _repos: string[] = []

  constructor() {
    const reposString = localStorage.getItem('dazn_pr_repos')

    if (reposString) {
      this._repos = JSON.parse(reposString)
    }
  }

  public get repos(): string[] {
    return this._repos
  }

  public clear(): void {
    this._repos = []
    localStorage.removeItem('dazn_pr_repos')
  }

  /**
   * @description add a valid DAZN repository to the current session
   *  it can be:
   *  - full qualified Github domain name
   *  - partially qualified github domain name
   *  - a full Github URI
   *  - a repo name
   * Only DAZN repos are accepted
   * @param {string|string[]} repos
   * @returns {string[]} list of repos in session
   * @memberof Session
   */
  public async saveRepo(repos: string | string[]) {
    repos = Array.isArray(repos) ? repos : [repos]
    const reposToCheck = repos.map((repo) =>
      this._isValidGithubURL(repo) ? repo.split('/').pop() ?? repo : repo
    ) // || repo to make typescript happy :(
    try {
      const checked = await Github.verifyDAZNRepos(reposToCheck)
      const filtered = (predicate: (value: RepoCheckResult) => boolean) =>
        checked.filter(predicate).map(({ repo }) => repo)
      const toAdd = filtered(
        ({ repo, exists }) => exists && !this._repos.includes(repo)
      )
      const notFound = filtered(({ exists }) => !exists)
      const alreadyAdded = filtered(
        ({ repo, exists }) => exists && this._repos.includes(repo)
      )
      this._repos.push(...toAdd)
      this._repos.sort((a, b) => a.localeCompare(b))
      localStorage.setItem('dazn_pr_repos', JSON.stringify(this._repos))
      console.info('Already added repo(s): ', ...alreadyAdded)
      console.warn('Repo(s) not found in getndazn: ', ...notFound)
    } catch (error) {
      console.log(error) // failing silently for now, let's integrate an error message in the UI? A PR is more than welcome ❤️
    }
    return this._repos
  }

  /**
   * @description remove a repo from the list by its name
   * @param {string} repoName
   * @returns {string[]}
   * @memberof Session
   */
  public async removeRepo(repoName: string) {
    if (!this._repos.includes(repoName)) {
      return this._repos
    }

    this._repos = this._repos.filter((name) => name !== repoName)
    localStorage.setItem('dazn_pr_repos', JSON.stringify(this._repos))
    return this._repos
  }

  /**
   * @description check if the repoUrl is a valid url
   * @private
   * @param {string} repoUrl
   * @returns {boolean}
   * @memberof Session
   */
  private _isValidGithubURL(repoUrl: string): boolean {
    const prefix = 'https://github.com/getndazn/'
    if (!repoUrl.startsWith(prefix)) {
      repoUrl = `${prefix}/${repoUrl}`
    }
    const parsedUrl = new URL(repoUrl)
    return parsedUrl.protocol != null && parsedUrl.hostname === 'github.com'
  }
}

const session = new Session()

export default session
