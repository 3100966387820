import * as React from 'react'

import Github from '../../../../utils/github'

import Logo from '../../../../components/Logo'

import styles from './styles.module.css'
import { withAuth0, Auth0ContextInterface } from '@auth0/auth0-react'
import { protectComponent } from '../../../../utils/protectComponent'

interface ILoginPageProps {
  auth0: Auth0ContextInterface
  onChangePage: any
}

interface ILoginPageState {
  token: string
  isLoggedIn: boolean
}

class LoginPage extends React.PureComponent<ILoginPageProps, ILoginPageState> {
  constructor(props: ILoginPageProps) {
    super(props)

    this.state = {
      token: '',
      isLoggedIn: false
    }

    this.handleChange = this.handleChange.bind(this)
    this.handleLogin = this.handleLogin.bind(this)
  }

  public render(): JSX.Element {
    const { token } = this.state

    return (
      <div className={styles.container}>
        <Logo />
        <input
          placeholder="Enter your GitHub token"
          type="password"
          value={token}
          className={styles.input}
          onChange={this.handleChange}
        />
        <button className={styles.button} onClick={this.handleLogin}>
          Login
        </button>
      </div>
    )
  }

  private handleChange(e: any): void {
    this.setState({
      token: e.target.value
    })
  }

  private handleLogin(): void {
    Github.token = this.state.token

    this.props.onChangePage('app')
  }
}

// @ts-expect-error
export default protectComponent(withAuth0(LoginPage))
