import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { AppState } from '@auth0/auth0-react/dist/auth0-provider'
import { auth0Config } from './config/auth0'
import { Auth0Provider } from '@auth0/auth0-react'
import { createBrowserHistory } from 'history'

const history = createBrowserHistory()

const onRedirectCallback = (appState: AppState) => {
  history.replace(appState?.returnTo ?? window.location.pathname)
}

ReactDOM.render(
  <Auth0Provider
    domain={auth0Config.domain}
    clientId={auth0Config.clientId}
    redirectUri={auth0Config.redirectUri}
    audience={auth0Config.audience}
    onRedirectCallback={onRedirectCallback}
    cacheLocation={'localstorage'}
  >
    <App />
  </Auth0Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
