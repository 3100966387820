import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as React from 'react'

import styles from './styles.module.css'

interface ILoaderProps {
  show: boolean
}

class Loader extends React.PureComponent<ILoaderProps> {
  public render(): JSX.Element {
    if (!this.props.show) {
      return <></>
    }
    return (
      <div className={styles.loaderContainer}>
        <FontAwesomeIcon icon={faSpinner} spin color="white" size="4x" />
        <span className={styles.loaderText}>Loading</span>
        <div className={styles.loaderBg}></div>
      </div>
    )
  }
}

export default Loader
