import * as React from 'react'

import Github from '../../../../utils/github'
import RepoRow from './RepoRow'

import Logo from '../../../../components/Logo'
import Session from '../../../../utils/session'

import styles from './styles.module.css'

interface ISidebarState {
  repoUrl: string
  repos: string[]
  reloading: boolean
}

interface ISidebarProps {
  repos: string[]
  onAddRepo: () => Promise<any>
  onChangePage: (page: 'app' | 'login') => void
}

class Sidebar extends React.PureComponent<ISidebarProps, ISidebarState> {
  constructor(props: ISidebarProps) {
    super(props)

    this.state = {
      repoUrl: '',
      repos: props.repos,
      reloading: false
    }

    this.handleChangeRepoUrl = this.handleChangeRepoUrl.bind(this)
    this.handleSaveRepoUrl = this.handleSaveRepoUrl.bind(this)
    this.handleLogout = this.handleLogout.bind(this)
    this.handleAdd = this.handleAdd.bind(this)
    this.handleRemove = this.handleRemove.bind(this)
    this.reloadPRs = this.reloadPRs.bind(this)
  }

  public render(): JSX.Element {
    return (
      <div className={styles.container}>
        <div className={styles.containerTop}>
          <div className={styles.action}>
            <Logo />
            <button className={styles.reloadButton} onClick={this.reloadPRs}>
              <svg
                className={this.state.reloading ? styles.animateSpin : ''}
                viewBox="0 0 16 16"
                version="1.1"
                width="18"
                height="18"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M8 2.5a5.487 5.487 0 00-4.131 1.869l1.204 1.204A.25.25 0 014.896 6H1.25A.25.25 0 011 5.75V2.104a.25.25 0 01.427-.177l1.38 1.38A7.001 7.001 0 0114.95 7.16a.75.75 0 11-1.49.178A5.501 5.501 0 008 2.5zM1.705 8.005a.75.75 0 01.834.656 5.501 5.501 0 009.592 2.97l-1.204-1.204a.25.25 0 01.177-.427h3.646a.25.25 0 01.25.25v3.646a.25.25 0 01-.427.177l-1.38-1.38A7.001 7.001 0 011.05 8.84a.75.75 0 01.656-.834z"
                ></path>
              </svg>
            </button>
            <button className={styles.button} onClick={this.handleLogout}>
              Logout
            </button>
          </div>
          <div className={styles.repoContainer}>
            <input
              className={styles.repoInput}
              type="text"
              placeholder="Repo url"
              value={this.state.repoUrl}
              onChange={this.handleChangeRepoUrl}
              onKeyPress={this.handleSaveRepoUrl}
            />
            <button className={styles.button} onClick={this.handleAdd}>
              Add
            </button>
          </div>
        </div>
        <ul className={styles.reposContainer}>
          {this.state.repos.map((repo) => (
            <RepoRow key={repo} repo={repo} handleRemove={this.handleRemove} />
          ))}
        </ul>
        <div className={styles.containerBottom}>
          version: {process.env.REACT_APP_VERSION || 'local'}
        </div>
      </div>
    )
  }

  private async reloadPRs() {
    this.setState({ reloading: true })
    await this.props.onAddRepo()
    this.setState({ reloading: false })
  }

  private async handleAdd() {
    if (this.state.repoUrl.length > 0) {
      this.setState({
        repos: await Session.saveRepo(this.state.repoUrl),
        repoUrl: ''
      })

      this.props.onAddRepo()
    }
  }

  private async handleRemove(repo: string) {
    this.setState({
      repos: await Session.removeRepo(repo),
      repoUrl: ''
    })

    this.props.onAddRepo()
  }

  private handleLogout(e: any): void {
    Github.clear()
    Session.clear()

    this.props.onChangePage('login')
  }

  private handleSaveRepoUrl(e: any): void {
    if (e.key === 'Enter') {
      this.handleAdd()
    }
  }

  private handleChangeRepoUrl(e: any): void {
    this.setState({
      repoUrl: e.target.value
    })
  }
}

export default Sidebar
