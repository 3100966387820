import * as React from 'react'
import axios from 'axios'

import Sidebar from '../../components/Sidebar'
import Columns from '../../components/Columns'
import Loader from '../../components/Loader'
import Feedback from '../../components/Feedback'

import styles from './styles.module.css'
import Github, {
  PullRequest,
  PullRequestReview,
  PullRequestReviews
} from '../../../../utils/github'
import Session from '../../../../utils/session'

import { apiConfig } from '../../../../config/api'

import { AppProvider } from '../../../../context/appContext'
import { withAuth0, Auth0ContextInterface } from '@auth0/auth0-react'
import { protectComponent } from '../../../../utils/protectComponent'

interface IDashboardPageProps {
  auth0: Auth0ContextInterface
  onChangePage: (page: 'app' | 'login') => void
}

interface IDashboardPageState {
  prsNoApprovals: PullRequest[]
  prsOneApproval: PullRequest[]
  prsReady: PullRequest[]
  nonDeletedBranches: any[]
  isLoadingPullRequests: boolean
  isLoadingBranches: boolean
}

class DashboardPage extends React.PureComponent<
  IDashboardPageProps,
  IDashboardPageState
> {
  constructor(props: IDashboardPageProps) {
    super(props)
    const prsNoApprovals = JSON.parse(
      localStorage.getItem('prsNoApprovals') ?? '[]'
    )
    prsNoApprovals.forEach(
      (p: PullRequest) => (p.reviews = new PullRequestReviews(...p.reviews))
    )
    const prsOneApproval = JSON.parse(
      localStorage.getItem('prsOneApproval') ?? '[]'
    )
    prsOneApproval.forEach(
      (p: PullRequest) => (p.reviews = new PullRequestReviews(...p.reviews))
    )
    const prsReady = JSON.parse(localStorage.getItem('prsReady') ?? '[]')
    prsReady.forEach(
      (p: PullRequest) => (p.reviews = new PullRequestReviews(...p.reviews))
    )
    this.state = {
      prsNoApprovals,
      prsOneApproval,
      prsReady,
      nonDeletedBranches: [],
      isLoadingBranches: true,
      isLoadingPullRequests: true
    }

    this.loadPullRequests = this.loadPullRequests.bind(this)
  }

  public async componentDidMount() {
    if (!Github.token) {
      this.props.onChangePage('login')
    }

    const team = window.location.pathname.replace('/', '')
    const { getAccessTokenSilently } = this.props.auth0
    const jwt = await getAccessTokenSilently()
    if (team) {
      const apiUrl = `${apiConfig.baseUrl}/repos?team=${team}`
      const {
        data: { repos }
      } = await axios.get(apiUrl, {
        headers: {
          Authorization: `Bearer ${jwt}`
        }
      })
      Session.clear()
      await Session.saveRepo(repos)
    }

    await this.loadPullRequests()
  }

  public render(): JSX.Element {
    return (
      <AppProvider value={this.state}>
        <div className={styles.container}>
          <Sidebar
            repos={Session.repos}
            onAddRepo={this.loadPullRequests}
            onChangePage={this.props.onChangePage}
          />
          <div className={styles.panelContainer}>
            <Loader show={this.state.isLoadingPullRequests} />
            <Columns />
          </div>
        </div>
        <Feedback />
      </AppProvider>
    )
  }

  private filterReviewers(list: PullRequestReview[]) {
    const filter: any = {}
    return list.filter((a: PullRequestReview) => {
      if (!filter[a.author.login]) {
        filter[a.author.login] = true
        return true
      }
      return false
    })
  }

  private filterPullRequests(
    list: PullRequest[],
    requiredApprovals: number,
    comparator: string = '='
  ): any[] {
    return list.filter((pr) => {
      if (comparator === '=') {
        return (
          this.filterReviewers(pr.reviews.approved).length === requiredApprovals
        )
      }

      return (
        this.filterReviewers(pr.reviews.approved).length > requiredApprovals
      )
    })
  }

  private sortByDateAscending(list: PullRequest[]): PullRequest[] {
    list.sort((pr1, pr2) => {
      return (
        new Date(pr1.createdAt).getTime() - new Date(pr2.createdAt).getTime()
      )
    })
    return list
  }

  private async loadPullRequests(): Promise<any> {
    const token = localStorage.getItem('dazn_pr_token')
    if (!token) {
      return
    }
    this.setState({ isLoadingPullRequests: true })
    const list = await Github.fetchPullRequests(Session.repos)
    const prsNoApprovals = this.sortByDateAscending(
      this.filterPullRequests(list, 0, '=')
    )
    const prsOneApproval = this.sortByDateAscending(
      this.filterPullRequests(list, 1, '=')
    )
    const prsReady = this.sortByDateAscending(
      this.filterPullRequests(list, 1, '>')
    )

    localStorage.setItem('prsNoApprovals', JSON.stringify(prsNoApprovals))
    localStorage.setItem('prsOneApproval', JSON.stringify(prsOneApproval))
    localStorage.setItem('prsReady', JSON.stringify(prsReady))
    this.setState({
      prsNoApprovals,
      prsOneApproval,
      prsReady,
      isLoadingPullRequests: false
    })
    return true
  }
}

// @ts-expect-error
export default protectComponent(withAuth0(DashboardPage))
