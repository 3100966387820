import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'

import {
  RmsFeedback,
  RmsButton
} from '@dazn/dx-rate-my-service-components-react'

export const Feedback = () => {
  const { user, getAccessTokenSilently } = useAuth0()
  return (
    <>
      <RmsFeedback
        displayName={'Pull Requests Reviews Board'}
        serviceName={'prr'}
        author={(user && user.name) || 'unknown'}
        fetchToken={getAccessTokenSilently}
        endpointEnv={process.env.REACT_APP_RMS_ENV as 'non-prod' | 'prod'}
      >
        <RmsButton />
      </RmsFeedback>
    </>
  )
}
