import * as React from 'react'

import IAppContext from './appContextInterface'

const AppContext: React.Context<IAppContext> = React.createContext<IAppContext>(
  {
    prsNoApprovals: [],
    prsOneApproval: [],
    prsReady: [],
    nonDeletedBranches: [],
    isLoadingPullRequests: true,
    isLoadingBranches: true
  }
)

const AppProvider = AppContext.Provider
const AppConsumer = AppContext.Consumer

export { AppContext as default, AppProvider, AppConsumer }
