import { graphql, GraphqlResponseError } from '@octokit/graphql'
export interface RepoCheckResult {
  repo: string
  exists: boolean
}

interface Author {
  avatarUrl: string
  login: string
}

export interface PullRequestReview {
  state:
    | 'PENDING'
    | 'COMMENTED'
    | 'APPROVED'
    | 'CHANGES_REQUESTED'
    | 'DISMISSED'
  author: Author
}

export class PullRequestReviews extends Array<PullRequestReview> {
  get approved(): PullRequestReview[] {
    return this.filter((r) => r.state === 'APPROVED')
  }

  get commented(): PullRequestReview[] {
    return this.filter((r) => r.state === 'COMMENTED')
  }

  get changeRequested(): PullRequestReview[] {
    return this.filter((r) => r.state === 'CHANGES_REQUESTED')
  }
}

interface pr {
  author: Author
  additions: number
  changedFiles: number
  createdAt: string
  updatedAt: string
  deletions: number
  isDraft: boolean
  repoName: string
  title: string
  url: string
}

export interface PullRequest extends pr {
  reviews: PullRequestReviews
}

interface VerifyRepoResult {
  organization: {
    [key: string]: {
      name: string
      id: string
    } | null
  }
}

interface PullRequestQueryResult {
  organization: {
    [key: string]: {
      name: string
      pullRequests: {
        nodes: Array<
          {
            reviews: {
              nodes: PullRequestReview[]
            }
          } & pr
        >
      }
    } | null
  }
}

class Github {
  private _token: string
  private readonly _DAZNGithubOrg = 'getndazn'
  public DAZNOrgUrl = 'https://github.com/getndazn/'

  constructor() {
    const token = localStorage.getItem('dazn_pr_token')
    this._token = token ?? ''
  }

  public get token() {
    return this._token
  }

  public set token(data: string) {
    localStorage.setItem('dazn_pr_token', data)
    this._token = data
  }

  public clear(): void {
    localStorage.removeItem('dazn_pr_token')
  }

  private async getRepositories(
    repositories: string[]
  ): Promise<VerifyRepoResult> {
    try {
      const result = await graphql<VerifyRepoResult>(
        generateRepositoryQuery(repositories),
        {
          headers: {
            authorization: `token ${this.token}`
          }
        }
      )
      return result
    } catch (error: unknown | GraphqlResponseError<VerifyRepoResult>) {
      if (error instanceof GraphqlResponseError) {
        console.error(error.message)
        return error.data
      } else {
        throw error
      }
    }
  }

  /**
   * @description fire a GET request to github.com/getndazn/<repo name> to find out if a repo exists
   * @param {string} repo
   * @returns {Promise<RepoCheckResult[]>}
   * @memberof Github
   *
   */
  public async verifyDAZNRepos(
    repositories: string[]
  ): Promise<RepoCheckResult[]> {
    const { organization } = await this.getRepositories(repositories)

    const foundRepositories = Object.entries(organization).reduce(
      (acc: any, [_, found]) => {
        if (found) {
          acc[found.name] = !!found.id
        }
        return acc
      },
      {}
    )
    return repositories.map((repo) => ({
      repo,
      exists: !!foundRepositories[repo]
    }))
  }

  public async fetchPullRequests(
    repositories: string[]
  ): Promise<PullRequest[]> {
    if (repositories.length === 0) {
      console.log("No repositories to fetch PR's for")
      return []
    }
    const repositoriesArray = splitIn40Length(repositories)
    const failed: string[] = []
    const result: PullRequest[] = []
    for (const repos of repositoriesArray) {
      const { organization }: PullRequestQueryResult = await graphql(
        generatePullRequestQuery(repos),
        {
          headers: {
            authorization: `token ${this.token}`
          }
        }
      )
      Object.entries(organization).reduce((acc: PullRequest[], [key, repo]) => {
        repo?.pullRequests.nodes.forEach((pr) => {
          if (pr.isDraft) {
            return
          }
          const pullRequest: PullRequest = {
            ...pr,
            repoName: repo.name,
            reviews: new PullRequestReviews(...pr.reviews.nodes)
          }
          acc.push(pullRequest)
        })
        if (!repo) {
          failed.push(repositories[parseInt(key.replaceAll('i', ''))])
        }
        return acc
      }, result)
    }
    if (failed.length > 0) {
      alert(
        `Can't fetch PR's for \n${failed.join(
          '\n'
        )}\nYou might not have access to it`
      )
    }
    return result
  }
}
const splitIn40Length = (repositories: string[]): string[][] => {
  const result: string[][] = []
  let array: string[] = []
  let count = 0
  for (const repo of repositories) {
    if (count > 40) {
      result.push(array)
      array = []
      count = 0
    }
    array.push(repo)
    count++
  }
  if (array.length > 0) {
    result.push(array)
  }
  return result
}

const generateRepositoryQuery = (repositories: string[]) => `
{
  organization(login: "getndazn") {
    ${repositories
      .map(
        (repo: string, index: number) => `
    i${index}: repository(name: "${repo}") {
      name
      id
    }`
      )
      .join('\n')}
  }
}
`
const generatePullRequestQuery = (repositories: string[]) => `
{
  organization(login: "getndazn") {
    ${repositories
      .map(
        (repo: string, index: number) => `
      i${index}: repository(name: "${repo}") {
        name
        pullRequests(first: 100, states: OPEN, orderBy: {field: CREATED_AT, direction: ASC}) {
          nodes {
            id
            additions
            changedFiles
            createdAt
            updatedAt
            deletions
            isDraft
            title
            url
            author {
              avatarUrl
              login
            }
            reviews(first: 100) {
              nodes {
                state
                author {
                  avatarUrl
                  login
                }
              }
            }
          }
        }
      }`
      )
      .join('\n')}
  }
}
`

const github = new Github()

export default github
